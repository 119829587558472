@keyframes fadeInLeftToRight {
	from {
		opacity: 0;
		transform: translateX(-50px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

.fadeInLeftToRight {
	animation: fadeInLeftToRight 1.25s ease-out;
}

@keyframes slideUpFadeIn {
	from {
		opacity: 0.25;
		transform: translateY(35px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.slideUpFadeIn {
	animation: slideUpFadeIn 0.7s ease-in-out;
}

/* Media query for phone screens, typically widths less than 768px */
@media (max-width: 768px) {
	.slideUpFadeIn {
		animation: slideUpFadeIn 0.65s ease-in-out;
	}
	@keyframes slideUpFadeIn {
		from {
			opacity: 0.25;
			transform: translateY(30px);
		}
		to {
			opacity: 1;
			transform: translateY(0);
		}
	}
}

.item-enter {
	opacity: 0.01;
	transform: scale(0.9);
}

.item-enter-active {
	opacity: 1;
	transform: translateX(0);
	transition:
		opacity 500ms,
		transform 500ms;
}

.item-exit {
	opacity: 1;
}

.item-exit-active {
	opacity: 0.01;
	transition: opacity 500ms;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

.Grid {
	animation: fadeIn 0.5s;
}

.Grid-exit {
	animation: fadeOut 0.5s;
}
